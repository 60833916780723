.DesktopNav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  font-family: var(--font-primary);
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--primary-acent);
  background-color: var(--primary);
  transition: all 0.3s;
}
.DesktopNav__Brand {
  color: var(--primary-acent);
  font-family: var(--font-primary);
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  margin: 0.25rem;
  text-decoration: none;
  transition: all 0.2s;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .DesktopNav {
    margin: 0;
  }
  .DesktopNav__Brand {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}
