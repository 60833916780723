@import url(https://fonts.googleapis.com/css?family=Coiny|Roboto:400,700);
:root {
  --appelsin: #f76027;
  --appelsin-num: 247, 96, 39;
  --white: #fff;
  --milk: #f2eee2;
  --black: #212325;
  --blue: #0084f6;
  --blue-num: 27, 145, 244;
  --grey: #b0b8bf;
  --grey-num: 176, 184, 191;
  --muted: #bfc2c7;
  --grey-dark: #616c74;
  --nightsky: #1d3040;
  --nightsky-num: 29, 48, 64;
  --font-primary: 'Roboto', sans-serif;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --section-offset: 1rem;
  --default-transition: all 0.3s ease;
  --corner-radius: 4px;
  --menu-height: 3.5rem;
}

:root {
  --font-color: var(--nightsky);
  --primary: var(--appelsin);
  --primary-num: var(--appelsin-num);
  --primary-acent: var(--white);
  --secondary: var(--blue);
  --secondary-num: var(--blue-num);
  --secondary-acent: var(--white);
  --dim: var(--nightsky);
  --dim-num: var(--nightsky-num);
  --background: var(--white);
  --background-secondary: var(--milk);
  --background-dark: var(--black);
  --link-color: var(--secondary);
}

/** reset **/

*,
::after,
::before {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  max-width: 100%;
}

a {
  color: inherit;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

nav,
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

button,
input,
textarea {
  font-size: var(--font-base);
  font-family: 'Roboto', sans-serif;
  font-family: var(--font-primary);
  line-height: var(--font-base);
  outline: none;
  border: none;
  width: 100%;
  padding: 0.125rem 0.375rem;
  margin: 0.125rem 0 0.25rem 0;
  resize: none;
}

/** end of reset **/

html {
  background-color: #212325;
  background-color: var(--background-dark);
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  background-color: var(--background);
  color: #1d3040;
  color: var(--font-color);
  font-family: 'Roboto', sans-serif;
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.section {
  margin-top: calc(2 * 1rem);
  margin-top: calc(2 * var(--section-offset));
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section:first-of-type {
  margin-top: 0;
}

.section--odd {
  background-color: #f2eee2;
  background-color: var(--background-secondary);
}

a {
  text-decoration: none;
  color: #0084f6;
  color: var(--link-color);
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: var(--font-hero); */
  font-weight: normal;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 5rem;
}

h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: bold;
}

button,
input,
textarea {
  border-radius: 3px;
}
textarea {
  min-height: 4px;
}

button {
  background-color: #f76027;
  background-color: var(--primary);
  color: #fff;
  color: var(--primary-acent);
  width: inherit;
  padding: 0.25rem 2rem;
  cursor: pointer;
}

.container {
  width: auto;
  padding: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: margin 0.3s;
}

@media (max-width: 576px) {
  button,
  input,
  textarea {
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.5rem;
    margin: 0.25rem 0 0.5rem 0;
    min-height: 2rem;
  }
  textarea {
    min-height: 6rem;
  }
}
@media (max-width: 768px) {
  .is-hidden-mobile {
    display: none;
  }
}
@media (max-width: 992px) {
  .is-hidden-touch {
    display: none;
  }
}

@media (min-width: 768px) {
  :root {
    --section-offset: 2rem;
  }
  h1 {
    font-size: 3.25em;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  .screen {
    /* min-height: calc(100vh - 6rem); */
  }
  .section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
@media (min-width: 992px) {
  :root {
    --section-offset: 3rem;
  }
  .section {
    padding-top: 2.5rem;
    padding-bottom: 4rem;
  }
  .container {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}
@media (min-width: 1200px) {
  .section {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  .container {
    margin-left: 15rem;
    margin-right: 15rem;
  }
}

.App {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6rem 1fr;
}
@media (max-width: 768px) {
  .App {
    grid-template-rows: 1fr;
  }
}

.LandingPage_Arrow__3CFCF {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.LandingPage_ArrowTip__7LE0J {
  --size: 2rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid var(--primary);
}

.DesktopNav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  font-family: var(--font-primary);
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--primary-acent);
  background-color: var(--primary);
  transition: all 0.3s;
}
.DesktopNav__Brand {
  color: var(--primary-acent);
  font-family: var(--font-primary);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 1.5rem;
  margin: 0.25rem;
  text-decoration: none;
  transition: all 0.2s;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .DesktopNav {
    margin: 0;
  }
  .DesktopNav__Brand {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
  }
}

.Footer_Wrapper__3IZnA {
  background-color: var(--background-dark);
  transition: background-color 0.3s;
}
.Footer_Copyright__3BkjK {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: bold;
}
.Footer_Content__2kS2C {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 1rem 1rem;
  transition: padding 0.3s;
}

.Footer_About__31E0R a {
  color: var(--muted);
  border-bottom: 1px dashed var(--primary);
}

.Footer_Wrapper__3IZnA {
  color: var(--muted);
  font-size: 0.9rem;
  font-weight: normal;
}

.Footer_ContactBlock__1nXJO {
  min-width: 250px;
}

a.Footer_Badge__3nVC3 {
  display: block;
  margin-top: 1.5rem;
  text-decoration: none;
  border-bottom: none;
}

@media (min-width: 768px) {
  .Footer_Content__2kS2C {
    padding: 3rem 4rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .Footer_Content__2kS2C {
    padding: 4rem 10rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .Footer_ContactBlock__1nXJO {
    grid-column: 1 / span 2;
  }
}

@media (min-width: 1200px) {
  .Footer_Content__2kS2C {
    padding: 4rem 15rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .Footer_ContactBlock__1nXJO {
    grid-column: unset;
  }
}

.FooterLink_Link__27zNc {
  color: inherit;
  font-weight: normal;
}

.FooterLink_ActiveLink__-ZEs9 {
  color: var(--primary);
}

