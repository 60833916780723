.Wrapper {
  background-color: var(--background-dark);
  transition: background-color 0.3s;
}
.Copyright {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: bold;
}
.Content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-content: flex-start;
  padding: 1rem 1rem;
  transition: padding 0.3s;
}

.About a {
  color: var(--muted);
  border-bottom: 1px dashed var(--primary);
}

.Wrapper {
  color: var(--muted);
  font-size: 0.9rem;
  font-weight: normal;
}

.ContactBlock {
  min-width: 250px;
}

a.Badge {
  display: block;
  margin-top: 1.5rem;
  text-decoration: none;
  border-bottom: none;
}

@media (min-width: 768px) {
  .Content {
    padding: 3rem 4rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .Content {
    padding: 4rem 10rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .ContactBlock {
    grid-column: 1 / span 2;
  }
}

@media (min-width: 1200px) {
  .Content {
    padding: 4rem 15rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .ContactBlock {
    grid-column: unset;
  }
}
