.App {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6rem 1fr;
}
@media (max-width: 768px) {
  .App {
    grid-template-rows: 1fr;
  }
}
